import React, { lazy, Suspense, useLayoutEffect } from "react";
import "./App.css";

// React Bootstrap
import { Spinner } from "react-bootstrap";

// React Router
import { Route, Routes, useLocation } from "react-router-dom";

// Routes
import ROUTES from "./constants/routes";

//Pages
const HomePage = lazy(() =>
  import(/* webpackChunkName: "home" */ "./pages/Home")
);
const ProjectPage = lazy(() =>
  import(/* webpackChunkName: "home" */ "./pages/Projects")
);
const ProwessPage = lazy(() =>
  import(/* webpackChunkName: "home" */ "./pages/Prowess")
);

const renderLoader = () => {
  const loaderStyles = {
    width: "100%",
    minHeight: "75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <>
      <div style={loaderStyles}>
        <Spinner animation="border" variant="dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </>
  );
};

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <>
      <Wrapper>
        <Suspense fallback={renderLoader()}>
          <Routes>
            <Route index path={ROUTES.HOME} element={<HomePage />} />
            <Route path={ROUTES.PROJECT} element={<ProjectPage />} />
            <Route path={ROUTES.PROWESS} element={<ProwessPage />} />
          </Routes>
        </Suspense>
      </Wrapper>
    </>
  );
}

export default App;
